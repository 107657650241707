<template>
  <div class="requeriments-step">
    <div class="tway-header-2 mb-4">
      Procesos y Estrategia
      <v-tooltip bottom max-width="20em">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <span
          >Metodologías o procedimientos que seguiráel especialista, incluye forma decomunicación con el cliente durante
          la ejecución del proyecto</span
        >
      </v-tooltip>
    </div>
    <v-file-input
      append-icon="mdi-paperclip"
      :prepend-icon="null"
      :rules="[() => (formData.processAndStrategyFile ? true : 'Campo Procesos y Estrategia es requerido')]"
      accept="application/pdf"
      class="mb-10 roboto"
      outlined
      color="rgb(150, 82, 223)"
      placeholder="Agrega tu archivo aquí"
      :value="formData.processAndStrategyFile"
      @change="(file) => upload(file, 'processAndStrategyFile')"
    />
    <div class="tway-header-2 mb-4">
      Cronograma y Plan de Trabajo
      <v-tooltip bottom max-width="20em">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <span>Fechas relevantes, hitos, esfuerzo hora hombre, holgura, entre otros</span>
      </v-tooltip>
    </div>
    <v-file-input
      append-icon="mdi-paperclip"
      :prepend-icon="null"
      :rules="[() => (formData.workplan ? true : 'Campo Cronograma y Plan de Trabajo es requerido')]"
      class="mb-10 roboto"
      outlined
      color="rgb(150, 82, 223)"
      placeholder="Agrega tu archivo aquí"
      accept="application/pdf"
      :value="formData.workplan"
      @change="(file) => upload(file, 'workplan')"
    />
    <div class="tway-header-2 mb-4">
      Consideraciones Generales
      <v-tooltip bottom max-width="20em">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="deep-purple" dark v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
        </template>
        <span
          >Condiciones comerciales, aceptación de la oferta, precios, condiciones de pago y facturación, entrega,
          confidencialidad, entre otros.</span
        >
      </v-tooltip>
    </div>
    <v-file-input
      append-icon="mdi-paperclip"
      :prepend-icon="null"
      :rules="[() => (formData.generalConsiderations ? true : 'Campo Requisitos técnicos es requerido')]"
      accept="application/pdf"
      class="mb-10 roboto"
      outlined
      color="rgb(150, 82, 223)"
      placeholder="Agrega tu archivo aquí"
      :value="formData.generalConsiderations"
      @change="(file) => upload(file, 'generalConsiderations')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidatorsService from "@/services/validatorsService";

export default {
  mounted() {
    if (this.stepIsComplete(this.stepIndex)) {
      let stepData = this.getStep(this.stepIndex);
      this.formData = { ...stepData.data };
    } else {
      this.setInProgress(this.stepIndex);
    }
  },
  data() {
    return {
      stepIndex: 1,
      formData: {
        processAndStrategyFile: null,
        workplan: null,
        generalConsiderations: null,
      },
    };
  },
  methods: {
    ...mapActions("applyForProject", ["setInProgress", "setComplete", "setIncomplete", "setStepData", "resetStep"]),
    upload(file, key) {
      this.formData[key] = file;
      let { processAndStrategyFile, workplan, generalConsiderations } = this.formData;
      if (processAndStrategyFile && workplan && generalConsiderations) {
        this.setComplete(this.stepIndex);
        this.setStepData({
          index: this.stepIndex,
          data: { ...this.formData },
        });
      } else {
        this.setStepData({
          index: this.stepIndex,
          data: { ...this.formData },
        });
        this.setInProgress(this.stepIndex);
      }
    },
    required: (propertyType) => ValidatorsService.required(propertyType),
  },
  computed: {
    ...mapGetters({
      stepIsComplete: "applyForProject/getStepIsComplete",
      getStep: "applyForProject/getStep",
    }),
  },
};
</script>

<style scoped>
.requeriments-step .v-application .primary--text {
  caret-color: rgb(150, 82, 223);
  color: rgb(150, 82, 223) !important;
}
.requeriments-step >>> .v-input__icon {
  transform: rotate(-30deg) !important;
}
</style>
